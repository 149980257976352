<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-510"
  ></div>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-510 pt-xl md:p-[4%]">
    <div
      class="w-full h-full px-0 mx-auto desktop:md:max-w-screen-xl touch:md:max-w-screen-xl desktop:lg:max-w-screen-xl"
    >
      <div
        class="relative flex items-center justify-center h-full mx-auto my-auto"
      >
        <div
          class="relative w-full h-full max-h-full marker:overflow-hidden rounded-alt-md md:rounded-alt-lg md:flex md:flex-col md:justify-center"
        >
          <div
            ref="el"
            class="h-full md:h-auto md:max-h-[100%] bg-white overflow-auto md:rounded-alt-lg js-dialog-scroll-container"
          >
            <template v-if="dialogStore.headline">
              <div class="sticky top-0 z-510 min-h-[80px]">
                <div
                  class="bg-white px-sm pt-[53px] min-h-[110px]"
                  :class="{
                    'flex justify-center items-center border-b-1 border-minilu-border-light pb-[24px]':
                      isScrolled,
                  }"
                >
                  <HeadlineDynamic
                    :headline="dialogStore.headline"
                    level="h1"
                    :has-decoration="!isScrolled"
                    mobile-full-deco-width
                    container-classes="w-full"
                    classes="break-keep whitespace-break-spaces"
                  />
                  <button
                    type="button"
                    class="absolute text-xl cursor-pointer text-grey-dark top-[22px] right-md"
                    @click="dialogStore.closeDialog(DialogResponses.CANCEL)"
                  >
                    <FaIcon icon-class="fas fa-xmark" />
                  </button>
                </div>
              </div>
            </template>
            <slot />
          </div>
          <button
            v-if="!dialogStore.headline"
            type="button"
            class="absolute text-xl cursor-pointer text-grey-dark top-md right-md"
            @click="dialogStore.closeDialog(DialogResponses.CANCEL)"
          >
            <FaIcon icon-class="fas fa-xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useDialogStore, DialogResponses } from '~/stores/useDialogStore';
import FaIcon from '@/components/fa-icon.vue';
import { onClickOutside } from '@vueuse/core';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/headline-dynamic.vue';

const el = ref<HTMLElement | null>(null);

const { y } = useScroll(el);
const dialogStore = useDialogStore();

const isScrolled = computed(() => {
  return y.value > 10;
});

onMounted(() => {
  usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});

watch(useRouter().currentRoute, () => {
  if (dialogStore.open) dialogStore.closeDialog();
});

onClickOutside(el, () => dialogStore.closeDialog());
</script>
